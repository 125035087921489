<template>
  <page-wrapper :no-access="!haveAccess" :loading="loading">
    <v-toolbar elevation="0">
      <v-toolbar-title><h3>{{ header }} <span v-if="isArchive"> (Архив)</span></h3></v-toolbar-title>
      <v-spacer/>
      <router-link :to="listDocumentsLink" v-slot="{href}" custom class="mx-3">
        <a :href="href" style="color: #000 !important; text-decoration:none;"><v-icon>mdi-close</v-icon></a>
      </router-link>
    </v-toolbar>
    <v-divider></v-divider>
    <div style="position: relative">
      <div class="inner">
        <v-navigation-drawer
            permanent
            width="60px"
            v-model="nav"
            absolute
        >
          <v-list
              dense
              nav
          >
            <v-list-item-group
                v-model="group"
                active-class=""
            >
              <v-list-item link :to="item.to"
                           v-for="item in navigateItems"
                           :key="item.key">
                <v-list-item-content>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <div style="margin-left: 60px; position: relative; min-height: 300px">
          <slot></slot>
        </div>
      </div>
    </div>
  </page-wrapper>
</template>

<script>
import PageWrapper from "../../components/PageWrapper";

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "DocumentView",
  components: {
    PageWrapper
  },
  props: ['header', 'isArchive', 'loading'],
  data: function () {
    return {
      group: null,
      nav: null,
    }
  },
  watch: {
    $route() {
      this.init()
    },
    currentDocument() {
      // нужно чтобы ссылка на документ работала даже без указания typeID
      if (this.currentDocument.type) {
        if (this.currentDocument.type + '' !== this.$route.params.typeID) {
          this.$router.replace({name: this.$route.name, params: {typeID: this.currentDocument.type + '', docID: this.$route.params.docID}})
        }
      }
    },
    header() {
      if (this.header) {
        document.title = this.header
      } else {
        document.title = this.$route.meta.title
      }
    },
  },
  computed: {
    ...mapState('document', ['currentDocument', 'haveAccess']),
    listDocumentsLink() {
      return '/documents/'+this.$route.params.typeID+'/list'
    },
    navigateItems() {
      let baseLink = '/documents/'+this.$route.params.typeID+'/'+this.$route.params.docID
      return [
        {
          to: baseLink + '/main',
          icon: 'mdi-information-outline',
          key: 'main'
        },
        {
          to: baseLink + '/comments',
          icon: 'mdi-comment-text-multiple',
          key: 'comments'
        },
        {
          to: baseLink + '/files',
          icon: 'mdi-paperclip',
          key: 'files'
        },
      ]
    }
  },
  methods: {
    ...mapActions('document', ['fetchCurrentDocument']),
    ...mapMutations('document', ['onDestroyDoc']),
    init() {
      if (this.$route.params.docID !== '' + this.currentDocument.id) {
        this.onDestroyDoc()
      }
      this.fetchCurrentDocument(this.$route.params.docID)
    },
  },
  mounted() {
    this.init()
    this.group = null
  },
}
</script>

<style scoped>
.inner {
  position: relative;
  height: auto;
  min-height: 100%;
}
</style>