<template>
  <document-view :header="currentDocument.name" :loading="currentDocumentLoading">
    <v-card flat>

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-file-input v-model="currentFile" label="Добавить файл">
            </v-file-input>
          </v-col>
          <v-expand-transition>
            <v-col cols="12" v-show="currentFile">
              <v-textarea
                  label="Комментарий"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  v-model="remark"
              >
                <template v-slot:append-outer>
                  <v-btn
                      @click="sendFile"
                      :loading="fileSendLoading"
                  >
                    <v-icon :color="sendBtnColor">mdi-send</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-data-table
                v-if="currentDocument.attachments"
                :headers="filesHeaders"
                :items="currentDocument.attachments"
                item-key="id"
                disable-pagination
                hide-default-footer
                class="elevation-1"
                sort-by="created"
                sort-desc
                dense>
              <template v-slot:item.icon="{ item }">
                <v-icon v-if="['rfc822', 'msg', 'eml'].includes(item.ext)"> mdi-email </v-icon>
                <v-icon v-else-if="['html'].includes(item.ext)"> mdi-web </v-icon>
                <i v-else :class="fileIconClasses(item)"  style="font-size:24px;"></i>
              </template>
              <template v-slot:item.created="{ item }">
                {{formatTime(item.created)}}
              </template>
              <template v-slot:item.download="{ item }">
                <a target="_blank" style="color: #000 !important; text-decoration:none;" :href="itemToFileLink(item)"><v-icon>mdi-download</v-icon></a>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>

    </v-card>
  </document-view>
</template>

<script>
import DocumentView from "./DocumentView";
import {loadFileLink} from "../../api/api";
import {documentAddFile} from "../../api/document";
import moment from 'moment-timezone'
import {mapState} from "vuex";


export default {
  name: "Files",
  data: function () {
    return {
      remark: '',
      currentFile: null,
      fileSendLoading: false,
      sendBtnColor: '',
      filesHeadersBase: [
        { value: 'icon'},
        { text: 'Добавлен', value: 'created', width: '10%'},
        { text: 'Создатель', value: 'creatorName' },
        { text: 'Имя', value: 'name' },
        { text: 'Расширение', value: 'ext' },
      ],
    }
  },
  computed: {
    ...mapState('document', ['currentDocument', 'currentDocumentLoading']),
    filesHeaders() {
      for (let att of this.currentDocument.attachments) {
        if (att.remark) {
          return this.filesHeadersBase.concat([
            { text: 'Комментарий', value: 'remark' },
            { text: 'Скачать', value: 'download' },
          ])
        }
      }
      return this.filesHeadersBase.concat([
        { text: 'Скачать', value: 'download' },
      ])
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    itemToFileLink(item) {
      return loadFileLink(item.id)
    },
    sendFile() {
      if (!this.currentFile) {return}
      this.fileSendLoading = true
    documentAddFile(this.currentDocument.id, this.currentFile, this.remark, this.user.personalEqmID, this.$store.getters.getAuthToken)
          .then((r) => {
            if (r.success) {
              this.currentFile = null
              this.remark = ''
              this.$store.dispatch('fetchCurrentDocument', this.$route.params.docID)
              this.sendBtnColor = ''
            } else {
              this.sendBtnColor = 'error'
              console.log('ошибка отправки файла', r)
            }
          })
      .finally(() => {
        this.fileSendLoading = false
      })
    },
    formatTime(t) {
      return moment(t).format('DD-MM-YYYY HH:mm:ss');
    },
    fileIconClasses(item) {
      let res = ['fa']
      switch (item.ext) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'JPG':
        case 'heic':
          res.push('fa fa-file-image-o')
          break
        case 'mov':
        case 'mp4':
          res.push('fa fa-file-movie-o')
          break
        case 'docx':
        case 'doc':
          res.push('fa-file-word-o')
          break
        case 'txt':
        case 'delivery-status':
          res.push('fa-file-text-o')
          break
        case 'pdf':
          res.push('fa-file-pdf-o')
          break
        case 'xlsx':
          res.push('fa-file-excel-o')
          break
        case 'zip':
          res.push('fa-file-archive-o')
          break
        default:
          res.push('fa-file-o')
      }
      return res
    }
  },
  components: {
    'DocumentView': DocumentView,
  }
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
</style>