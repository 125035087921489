<template>
  <div>
    <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="gray"
        indeterminate
        class="my-10"
    ></v-progress-circular>
    <v-row v-else-if="noAccess" class="mt-10">
      <v-col>
        <h2>Отказано в доступе</h2>
      </v-col>
    </v-row>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: "PageWrapper",
  data: function () {
    return {
      warn: false
    }
  },
  props: ['loading', 'noAccess'],
}
</script>

<style scoped>

</style>